import 'intersection-observer';
import { gsap, Power0, Power4 } from 'gsap';
import mediaQuery from './../util/mediaQuery';
import teaserVideo from './teaserVideo';
import Glide, { Controls, Autoplay, Keyboard, Swipe } from '@glidejs/glide/dist/glide.modular.esm';

const carouselSelector = '.project-carousel';
const slideSelector = '.project-carousel__slide';
const slideInnerSelector = '.glide__slide-container';
const animationDuration = 600;
let isCarouselVisible = false;
let isMouseOver = false;

let glide = null;

let slidesInner;

let previous = [];
let active = [];
let next = [];
let end = [];
let start = [];

let teaserStart = [];
let teaserPrevious = [];
let teaserActive = [];
let teaserNext = [];
let teaserEnd = [];

gsap.ticker.fps(24);

function addObserver() {
  let observer = new IntersectionObserver(observables => {
    observables.forEach(observable => {
      // L'élément devient visible
      if (observable.isIntersecting) {
        isCarouselVisible = true;
        renderSlides(0);
        initRotate(animationDuration);
      } else {
        isCarouselVisible = false;
        pauseVideos();
      }
    })
  });

  Array.from(document.querySelectorAll(carouselSelector)).forEach(carousel => {
    observer.observe(carousel);
  });
}

function initRotate(duration) {
  mediaQuery.watcher(mediaQuery.breakpointUp.md,function(matches) {
    const slides = document.querySelectorAll(slideSelector);
    const track = document.querySelector('.glide__track');
    const carousel = document.querySelector(carouselSelector);

    const rotationX = -2;
    const perspective = 300;
    gsap.set(track, { force3D: true, rotationX: rotationX });
    gsap.set(slides, { force3D: true, perspective: perspective });

    if (matches) {
      carousel.addEventListener('mouseenter', () => {
        isMouseOver = true;
        gsap.killTweensOf(track);
        gsap.killTweensOf(slides);

        const tl =  gsap.timeline();
        tl.to(track, duration / 1000, { force3D: true, rotationX: 0, ease: Power4.in })
          .add(gsap.to(slidesInner, duration / 1000, { force3D:true, z: 0, rotateY: 0, ease: Power4.in }), 0)
      });

      carousel.addEventListener('mouseleave', () => {
        gsap.killTweensOf(track);
        gsap.killTweensOf(slides);

        gsap.to(track, duration / 1000, { force3D: true, rotationX: rotationX });
        renderSlides(duration);

        isMouseOver = false;
      });
    } else {
      gsap.killTweensOf(track);
      gsap.killTweensOf(slides);
      gsap.set(track, { force3D: true, rotationX: 0 })
      gsap.set(slidesInner, { force3D:true, z: 0, rotateY: 0});
    }
  });
}

function playActiveVideo() {
  teaserActive = active.map(el => el.querySelector('.teaser-media'));
  const teaserMedia = teaserActive.filter(el => el.closest(slideSelector).classList.contains('glide__slide--active'));
  teaserMedia.forEach(el => {
    Array.from(el.querySelectorAll('.js-background-video')).forEach(video => {
      teaserVideo.play(video);
    });
  });
}

function updateActiveSlide(duration) {
  const tl =  gsap.timeline();
  tl.to(teaserStart, duration / 1000, { force3D:true, autoAlpha: 0, ease: Power0.inOut })
    .add(gsap.to(teaserPrevious, duration / 1000, { force3D:true, autoAlpha: 0.3, ease: Power0.inOut }), 0)
    .add(gsap.to(teaserActive, duration / 1000, { force3D:true, autoAlpha: 1, ease: Power0.inOut }), 0)
    .add(gsap.to(teaserNext, duration / 1000, { force3D:true, autoAlpha: 0.3, ease: Power0.inOut }), 0)
    .add(gsap.to(teaserEnd, duration / 1000, { force3D:true, autoAlpha: 1, ease: Power0.inOut }), 0);

  if (!isMouseOver) {
    renderSlides(duration);
  }
}

function renderSlides(duration) {
  const tl =  gsap.timeline();
  tl.to(start, duration / 1000, { force3D:true, z: '-10px', ease: Power0.inOut })
    .add(gsap.to(previous,  duration / 1000, { force3D:true, z: '-13px', rotateY: -2, ease: Power0.inOut}), 0)
    .add(gsap.to(active,  duration / 1000, { force3D:true, z: '-15px', rotateY: -2, ease: Power0.inOut }), 0)
    .add(gsap.to(next, duration / 1000, { force3D:true, z: '-17px', rotateY: -2, ease: Power0.inOut }), 0)
    .add(gsap.to(end, duration / 1000, { force3D:true, z: '-18px', rotateY: -2, ease: Power0.inOut }), 0);
}

function pauseVideos() {
  Array.from(slidesInner).forEach(el => {
    Array.from(el.querySelectorAll('.js-background-video')).forEach(video => {
      teaserVideo.pause(video);
    });
  });
}

const Deep = function(Glide, Components, Events) {
  let maxSlideIndex;

  const Constructor = {
    getSlideIndex() {
      start = [];
      previous = [];
      active = [];
      next = [];
      end = [];

      teaserStart = [];
      teaserPrevious = [];
      teaserActive = [];
      teaserNext = [];
      teaserEnd = [];


      slidesInner.forEach((el) => {
        const slideIndex = Number(el.dataset.index);
        if (slideIndex === Glide.index) {
          active.push(el);
        } else if (Glide.index === maxSlideIndex) {
          if (slideIndex === maxSlideIndex - 2) {
            start.push(el);
          } else if (slideIndex === maxSlideIndex - 1) {
            previous.push(el);
          }  else if (slideIndex === 0) {
            next.push(el);
          } else if (slideIndex === 1) {
            end.push(el);
          }
        } else if (Glide.index === maxSlideIndex - 1) {
          if (slideIndex === maxSlideIndex - 3) {
            start.push(el);
          } else if (slideIndex === maxSlideIndex - 1) {
            previous.push(el);
          }  else if (slideIndex === maxSlideIndex ) {
            next.push(el);
          } else if (slideIndex === 1) {
            end.push(el);
          }
        } else if (Glide.index === 0) {
          if (slideIndex === maxSlideIndex - 1) {
            start.push(el);
          } else if (slideIndex === maxSlideIndex) {
            previous.push(el);
          }  else if (slideIndex === 1) {
            next.push(el);
          } else if (slideIndex === 2) {
            end.push(el);
          }
        } else if (Glide.index === 1) {
          if (slideIndex === maxSlideIndex) {
            start.push(el);
          } else if (slideIndex === 0) {
            previous.push(el);
          }  else if (slideIndex === 2) {
            next.push(el);
          } else if (slideIndex === 3) {
            end.push(el);
          }
        } else {
          if (slideIndex === Glide.index - 2) {
            start.push(el);
          } else if (slideIndex === Glide.index - 1) {
            previous.push(el);
          }  else if (slideIndex === Glide.index + 1) {
            next.push(el);
          } else if (slideIndex === Glide.index + 2) {
            end.push(el);
          }
        }
      });

      teaserStart = start.map(el => el.querySelector('.teaser-media'));
      teaserPrevious = previous.map(el => el.querySelector('.teaser-media'));
      teaserActive = active.map(el => el.querySelector('.teaser-media'));
      teaserNext = next.map(el => el.querySelector('.teaser-media'));
      teaserEnd = end.map(el => el.querySelector('.teaser-media'));

    },

    mount() {
      slidesInner = Components.Html.slides
        .concat(Components.Clones.items)
        .map(el => el.querySelector(slideInnerSelector));

      maxSlideIndex = slidesInner
        .map(el => el.dataset.index);
      maxSlideIndex = Math.max(...maxSlideIndex);

      this.getSlideIndex();
      updateActiveSlide();
      playActiveVideo();
      addObserver();
    },
  }

  Events.on('run', () => {
    if (isCarouselVisible) {
      Constructor.getSlideIndex();
      pauseVideos();
      updateActiveSlide(animationDuration);
    }
  });

  Events.on('run.after', () => {
    playActiveVideo();
  });

  return Constructor
}

function initGlideSlider() {
  if (window.matchMedia(mediaQuery.breakpointUp.sm).matches) {
    if (glide) {
      glide.destroy();
      glide = null;
    }
    glide = new Glide(carouselSelector, {
      type: 'carousel',
      autoplay: 5000,
      animationDuration: animationDuration,
      gap: 0,
      perView: 2,
      focusAt: 'center',
    });
    glide.mount({ Controls, Autoplay, Keyboard, Swipe, Deep });
  } else {
    if (glide) {
      glide.destroy();
      glide = null;
    }
    glide = new Glide(carouselSelector, {
      type: 'carousel',
      autoplay: 5000,
      animationTimingFunc: 'ease-in-out',
      animationDuration: animationDuration,
      perView: 1,
      focusAt: 'center',
    });
    glide.mount({ Controls, Swipe });
  }
}

function init() {
  initGlideSlider();
  let resizeThrottled = false;
  window.addEventListener('resize', function() {
    if (!resizeThrottled) {
      resizeThrottled = true;
      setTimeout(function() {
        initGlideSlider();
        resizeThrottled = false;
      }, 250);
    }
  });
}

export default { init }
