import { gsap, Power0 } from 'gsap';
import teaserVideo from './teaserVideo';

gsap.ticker.fps(24);

function init() {
  const projectMenu = document.querySelector('.project-menu');
  if(projectMenu) {
    const siteBackgroundVideo =  document.querySelectorAll('.layer--background .js-background-video');
    const projectMenuToggle = projectMenu.querySelector('.project-menu__toggle');
    const projectMenuBackground = projectMenu.querySelector('.project-menu__background')
    const projectMenuList = projectMenu.querySelector('.project-menu__list');
    const projectMenuItems = projectMenu.querySelectorAll('.project-menu__item');
    const projectMenuButtons = projectMenu.querySelectorAll('.project-menu__toggle__trigger');

    projectMenuToggle.addEventListener('click', () => {
      projectMenu.classList.toggle('opened');
      Array.from(projectMenuButtons).forEach(el => el.toggleAttribute('hidden'));
      if (projectMenu.classList.contains('opened')) {
        projectMenuToggle.setAttribute('aria-expanded', 'true');
        gsap.killTweensOf(projectMenuList);
        gsap.fromTo(projectMenuList, .6,
          { y: -16, autoAlpha: 0 },
          { force3D:true, y:0, autoAlpha: 1, ease:Power0.easeout }
        );
      } else {
        projectMenuToggle.setAttribute('aria-expanded', 'false');
        gsap.killTweensOf(projectMenuList);
        gsap.fromTo(projectMenuList, .2,
          { y:0, autoAlpha: 1 },
          { force3D:true, y: -16, autoAlpha: 0, ease:Power0.easeout }
        );
      }
    });

    projectMenuList.addEventListener('mouseover', () => {
      gsap.killTweensOf(projectMenuBackground);
      document.body.classList.add('project-menu-opened');
      gsap.to(projectMenuBackground, .4, { autoAlpha: 1, onComplete: () => {
        Array.from(siteBackgroundVideo).forEach(video => { video.pause() });
      }});
    });

    projectMenuList.addEventListener('mouseout', () => {
      gsap.killTweensOf(projectMenuBackground);
      document.body.classList.remove('project-menu-opened');
      Array.from(siteBackgroundVideo).forEach(video => { video.play() });
      gsap.to(projectMenuBackground, .4, { autoAlpha: 0 });
    });

    Array.from(projectMenuItems).forEach((item) => {
      item.addEventListener('mouseover', () => {
        const itemIndex = item.dataset.menuIndex;
        const media = projectMenu.querySelector(`.project-menu__media[data-menu-index="${itemIndex}"]`);

        gsap.killTweensOf(media);
        gsap.to(media, .4, { autoAlpha: 1 });

        const videos = media.querySelectorAll('.js-background-video');
        Array.from(videos).forEach(video => { teaserVideo.play(video) });
      });

      item.addEventListener('mouseout', () => {
        const itemIndex = item.dataset.menuIndex;
        const media = projectMenu.querySelector(`.project-menu__media[data-menu-index="${itemIndex}"]`);

        gsap.killTweensOf(media);
        gsap.to(media, .4, { autoAlpha: 0 });

        const videos = media.querySelectorAll('.js-background-video');
        Array.from(videos).forEach(video => { teaserVideo.pause(video) });
      });
    });
  }
}

export default { init };
