/*
 * Media Query Watch
 *
 * BOOTSTRAP MEDIA QUERIES:
 * up(sm
 */


function watcher(mediaQuery, layoutChangedCallback) {
  var mql = window.matchMedia(mediaQuery);
  mql.addListener(function (e) { return layoutChangedCallback(e.matches); });
  layoutChangedCallback(mql.matches);
}

const breakpointUp = {
  sm: '(min-width: 576px)',
  md: '(min-width: 768px)',
  lg: '(min-width: 992px)',
  xl: '(min-width: 1200px)',
}

export default { watcher, breakpointUp }
