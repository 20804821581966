import { gsap, Power0 } from 'gsap';

function play(video) {
  video.play();
  gsap.to(video.closest('.teaser-media__video'), .6, { autoAlpha: 1, ease: Power0.out });
}

function pause(video) {
  video.pause();
}

function hide(video) {
  gsap.to(video.closest('.teaser-media__video'), .6, {
    autoAlpha: 0,
    ease: Power0.out,
    onComplete: () => { pause(video) },
  });
}

export default { play, pause, hide }
