export default {
  path: '/project/:slug',
  name: 'single-project',
  transitions: [
  ],
  views: [
    {
      namespace: 'single-project',
      afterLeave() {
      },
      beforeEnter() {
      },
      afterEnter() {
      },
    },
  ],
};
