import VideoPlayer from './../modules/videoPlayer'
import VideoModal from './../modules/videoModal';
import { gsap } from 'gsap';
import ProjectMenu from './../modules/projectMenu';
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();
gsap.ticker.fps(24);

function init() {
  setTimeout(() => {
    VideoModal.init();
  }, 1000);

  Array.from(document.querySelectorAll('.js-smooth-scroll')).forEach(el => {
    el.addEventListener('click', (e) => {
      e.preventDefault();
      document.querySelector(el.getAttribute('href')).scrollIntoView({
        behavior: 'smooth',
      });
    });
  });

  ProjectMenu.init();
}

function unmount(container) {
  container = (container && (container instanceof Element || container instanceof HTMLDocument))
    ? container
    : document;

  VideoModal.unmount();
  VideoPlayer.unmountAll(container);
}

export default { init, unmount }
