const fadeOutDuration = 200;
const fadeInDuration = 400;

export default {
  path: '/',
  name: 'default',
  transitions: [
    {
      leave({ current }) {
        return new Promise((resolve) => {
          current.container.style.transition = `opacity ${fadeOutDuration}ms ease-out`;
          setTimeout(() => {
            resolve();
          }, fadeOutDuration);
        });
      },
      beforeEnter({ next }) {
        return new Promise((resolve) => {
          Array.from(next.container.querySelectorAll('.fade-down')).forEach(element => {
            element.style.transform = 'translate3D(0, -1rem, 0)';
            element.style.transition = `transform ${1.2 * fadeInDuration}ms ease-out`;
            element.style.willChange = 'transform';
          });

          Array.from(next.container.querySelectorAll('.fade-up')).forEach(element => {
            element.style.transform = 'translate3D(0, 1rem, 0)';
            element.style.transition = `transform ${2 * fadeInDuration}ms ease-out`;
            element.style.willChange = 'transform';
          });

          next.container.style.opacity = 0;
          next.container.style.transition = `opacity ${2 * fadeInDuration}ms ease-out`;
          setTimeout(() => {
            resolve();
          }, 50);
        });
      },
      enter({ next }) {
        return new Promise((resolve) => {
          next.container.style.opacity = 1;

          Array.from(next.container.querySelectorAll('.fade-up, .fade-down')).forEach(element => {
            element.style.transform = 'translate3D(0, 0, 0)';
          });

          setTimeout(() => {
            next.container.style.transition = '';
            next.container.style.opacity = '';
            Array.from(next.container.querySelectorAll('.fade-up, .fade-down')).forEach(element => {
              element.style.transition = '';
              element.style.transform = '';
              element.style.willChange = '';
            });
          }, 3 * fadeInDuration);

          resolve();
        })
      },
    },
  ],
  views: [
    {
      afterLeave() {
      },
      beforeEnter() {
      },
      afterEnter() {
      },
    },
  ],
};
