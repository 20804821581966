import { gsap } from 'gsap';
import projectCarousel from './../modules/projectCarousel';
import projectsListing from './../modules/projectsListing';

// const teaserMediaSelector = '.teaser-media';

gsap.ticker.fps(24);

export default {
  path: '/projects/',
  name: 'template-projects',
  transitions: [
  ],
  views: [
    {
      namespace: 'template-projects',
      afterLeave() {
      },
      beforeEnter() {
        projectCarousel.init();
      },
      afterEnter({ next }) {
        projectsListing.init(next.container);
      },
    },
  ],
};
