import multiScroll from '../modules/mutliscroll';

let isScrolling = false;

export default {
  path: '/agence',
  name: 'template-agency',
  transitions: [
  ],
  views: [
    {
      namespace: 'template-agency',
      afterLeave() {
        multiScroll.unmount();
      },
      beforeEnter() {
      },
      afterEnter() {
        multiScroll.mount();

        let tiles = document.querySelectorAll('.tile');

        window.addEventListener('scroll', () => {
          Array.from(tiles).forEach((tile) => {
            tile.style.pointEvents = 'none';
          });
          window.clearTimeout(isScrolling);
          isScrolling = setTimeout(() => {
            isScrolling = false;
            Array.from(tiles).forEach((tile) => {
              tile.style.pointEvents = 'initial';
            });
          }, 66);
        }, false);

        Array.from(tiles).forEach((tile) => {
          const video = tile.querySelector('.js-background-video');
          tile.addEventListener('mouseover', () => {
            video.play();
            tile.classList.add('hover');
          });

          tile.addEventListener('mouseout', () => {
            video.pause();
            tile.classList.remove('hover');
          });
        })
      },
    },
  ],
};
