import mediaQuery from './../util/mediaQuery';

let constrain = 1000;
let mouseOverContainer = document;
let nodeList;
let xy;
let lastRender = 0;
let counter = 0;


function transforms(x, y, el) {
  let box = el.getBoundingClientRect();
  let calcX = -(y - box.y - (box.height / 2)) / (constrain * 2);
  let calcY = (x - box.x - (box.width / 2)) / constrain;

  return `perspective(40px) rotateX(${-calcX}deg) rotateY(${calcY}deg)`;
}

function transformElement(el, xyEl) {
  el.style.transform  = transforms.apply(null, xyEl);
}

function animate() {
  Array.from(nodeList).forEach((el) => {
    const position = xy.concat([el]);
    transformElement(el, position);
  });

}

function render(time) {
    if(time - lastRender < 16) {
    requestAnimationFrame(render);
    return;
  }
  lastRender = time;
  counter++;

  if(counter %20 && counter != 0) {
    animate();
  }
  if(counter >= 60) {
    counter=0;
  }
  requestAnimationFrame(render);
}

function init(elements) {
  mediaQuery.watcher(mediaQuery.breakpointUp.xl,function(matches) {
    if (matches) {
      nodeList = elements;
      mouseOverContainer.onmousemove = function(e) {
        xy = [e.clientX, e.clientY];
        window.requestAnimationFrame(render);
      }
    }
  });
}

export default { init }
