/* eslint-disable no-unused-vars */
import './polyfills/DOMParser';
import barba from '@barba/core';
import barbaRouter from '@barba/router';
import { gsap } from 'gsap';
import common from './routes/common';
import VideoModal from './modules/videoModal';

gsap.ticker.fps(24);

barba.hooks.beforeLeave(() => {
  VideoModal.unmount();
})

barba.hooks.afterLeave(({ current, next }) => {
  // Hide background if a transition is needed
  const currentBackgroundData = current.container.querySelector('.js-background-data').dataset.background;
  const nextBackgroundData = next.container.querySelector('.js-background-data').dataset.background;

  if (currentBackgroundData !== nextBackgroundData) {
    const currentBackground = document.querySelectorAll('.layer--background');
    gsap.to(currentBackground, .3, {
      autoAlpha: 0,
      onComplete: () => {
        const siteBackgroundVideo = document.querySelectorAll('.js-background-video');
        Array.from(siteBackgroundVideo).forEach(video => { video.pause() });
      },
    });

    common.unmount(current);
  }
});

barba.hooks.enter(({ next, trigger }) => {
  // Back to top
  window.scrollTo(0, 0);

  let parser = new DOMParser();
  const nextDocument = parser.parseFromString(next.html, 'text/html');

  // Update body classes
  document.body.setAttribute('class', nextDocument.body.getAttribute('class'));

  // Update Menu
  document.body.replaceChild(nextDocument.getElementById('navLayer'), document.getElementById('navLayer'));
  if (document.body.classList.contains('admin-bar')) {
    document.body.replaceChild(nextDocument.getElementById('wpadminbar'), document.getElementById('wpadminbar'));
  }
});

barba.hooks.after(({ current, next }) => {
  const currentBackgroundData = current.container.querySelector('.js-background-data').dataset.background;
  const nextBackgroundData = next.container.querySelector('.js-background-data').dataset.background;

  if (currentBackgroundData !== nextBackgroundData) {
    const backgroundHTML = next.container.querySelector('.js-background-layer').innerHTML;
    const currentBackground = document.querySelector('.layer--background');
    const nextBackground = document.createElement('div');
    nextBackground.classList.add('layer');
    nextBackground.classList.add('layer--background');
    nextBackground.innerHTML = backgroundHTML;
    gsap.set(nextBackground, { autoAlpha: 0, onComplete: () => {
      currentBackground.parentNode.insertBefore(nextBackground, currentBackground);
      currentBackground.remove();
      gsap.to(nextBackground, 1, { autoAlpha: 1, onComplete: () => {
        if (!nextBackground.hasChildNodes) {
          nextBackground.style.display = 'none';
        } else {
          const siteBackgroundVideo = document.querySelectorAll('.js-background-video');
          Array.from(siteBackgroundVideo).forEach(video => { video.play() });
        }
      }});
    }});
  }

  common.init();
});

const init = (routes) => {
  barba.use(barbaRouter, { routes });
  barba.init({
    preventRunning: true,
    timeout: 3000,
    prevent: ({ el }) => el.classList && (el.classList.contains('ab-item') || el.classList.contains('no-barba')),
    transitions: routes.reduce((transitions, route) => [...transitions, ...(route.transitions || [])], []),
    views: routes.reduce((views, route) => [...views, ...(route.views || [])], []),
  });
}

export default { init };
