import mediaPerspective from './../modules/mediaPerspective';
import teaserVideo from './../modules/teaserVideo';
import mediaQuery from './../util/mediaQuery';

let sectionsObserver, firstSectionObserver;
let sections, container;
let teaserMediasArr;

function getTeaserMedias() {
  teaserMediasArr = {};
  const teaserMedias = container.querySelectorAll('.projects-listing__teaser-container__inner > a');
  Array.from(teaserMedias).forEach(el => {
    teaserMediasArr[el.dataset.projectId] = el;
  });
}

function updateActiveTeaser(entry) {
  const teaserMedia = teaserMediasArr[entry.target.dataset.projectId];

  if (entry.isIntersecting && entry.boundingClientRect.bottom > window.innerHeight/2 ) {
    teaserMedia.classList.add('active');
    Array.from(teaserMedia.querySelectorAll('.js-background-video')).forEach(video => {
      teaserVideo.play(video);
    });
  } else {
    teaserMedia.classList.remove('active');
    Array.from(teaserMedia.querySelectorAll('.js-background-video')).forEach(video => {
      teaserVideo.pause(video);
    });
  }
}

function initObservers() {
  sectionsObserver = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      updateActiveTeaser(entry);
    });
  }, {
    rootMargin: '-50% 0% -50% 0%',
  });

  firstSectionObserver =  new IntersectionObserver(entries => {
    entries.forEach(entry => {
      updateActiveTeaser(entry);
    });
  }, {
    rootMargin: '-50% 0% 0% 0%',
  });

  console.groupEnd();
}

function init(node) {
  container = node;
  const listingTeaserMediasContainer = container.querySelectorAll('.projects-listing__teaser-container__inner');
  mediaPerspective.init(listingTeaserMediasContainer);

  getTeaserMedias();
  initObservers();

  sections = container.querySelectorAll('.projects-listing__single');
  mediaQuery.watcher(mediaQuery.breakpointUp.xl,function(matches) {
    if (matches) {
      Array.from(sections).forEach((section, i) => {
        if (i > 0) {
          sectionsObserver.observe(section);
        } else {
          firstSectionObserver.observe(section);
        }
      });
    } else {
      sectionsObserver.disconnect();
      firstSectionObserver.disconnect();
    }
  });
}

export default { init }
