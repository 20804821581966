import Plyr from 'plyr';
import { gsap } from 'gsap';

window.players = {};

gsap.ticker.fps(24);

function init(HTMLElement) {
  const controls = HTMLElement.hasAttribute('controls') ? ['play', 'progress', 'fullscreen'] : false;
  const autoplay = HTMLElement.hasAttribute('autoplay');
  const loop = { active: HTMLElement.hasAttribute('loop') };
  const muted = HTMLElement.hasAttribute('muted');
  const hideControls = false;
  const uniqID = HTMLElement.dataset.videoId;
  const iframe = HTMLElement.querySelector('iframe');
  let provider = {};

  if (iframe) {
    const src = iframe.getAttribute('src');
    if(src.indexOf('vimeo.com') > -1) {
      provider = {
        vimeo: {
          byline: false,
          controls: false,
          portrait: false,
          title: false,
          transparent: true,
        },
      }
    }
  }

  HTMLElement.style.display = 'none';
  gsap.set(HTMLElement, { autoAlpha: 0 });

  window.players[uniqID] = new Plyr(HTMLElement, {
    ...provider,
    controls,
    autoplay,
    loop,
    muted,
    hideControls,
  });

  window.players[uniqID].on('ready', () => {
    window.players[uniqID].elements.container.dataset.videoId = uniqID;
  })

  // Animate if it was hidden
  window.players[uniqID].on('playing', () => {
    HTMLElement.style.display = null;
    gsap.to(HTMLElement, 1, { autoAlpha: 1 });
  });

  return window.players[uniqID];
}

function get(HTMLElement) {
  if (!HTMLElement || !HTMLElement.dataset.videoId) {
    return
  }
  return window.players[HTMLElement.dataset.videoId];
}

function unmount(HTMLElement) {
  const player = get(HTMLElement);
  if (player) {
    HTMLElement.remove();
    player.destroy();
    delete window.players[HTMLElement.dataset.videoId];
  }
}

function unmountAll(container) {
  container = (container && (container instanceof Element || container instanceof HTMLDocument))
    ? container
    : document;
  Array.from(container.querySelectorAll('.plyr')).forEach(el => {
    unmount(el);
  })
}

export default { init, get, unmount, unmountAll }
