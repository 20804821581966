// Import everything from autoload
//import './autoload/_bootstrap.js'

import './polyfills/closest';
import Intro from './modules/intro';
import barbaController from './barbaController';
import common from './routes/common';
import defaultBarba from './routes/default-barba';
import templateAgency from './routes/template-agency';
import templateProjects from './routes/template-projects';
import templateRse from './routes/template-rse';
import singleProject from './routes/single-project';

document.addEventListener('DOMContentLoaded', () => {
  Intro.init();
  common.init();

  // Init Barbajs with routes
  barbaController.init([
    defaultBarba,
    templateAgency,
    templateProjects,
    templateRse,
    singleProject,
  ]);
});
