import rseProjectSlider from './../modules/rseProjectSlider';

export default {
  path: '/rse/',
  name: 'template-rse',
  transitions: [
  ],
  views: [
    {
      namespace: 'template-rse',
      afterLeave() {},
      beforeEnter() {
        rseProjectSlider.init();
      },
      afterEnter() {},
    },
  ],
};
