import 'jquery';
import { Power4 } from 'gsap';
import './../util/multiscroll.responsiveExpand.min.js';
import 'multiscroll.js/dist/jquery.multiscroll.extensions.min.js';

function unmount() {
  $('#multiscroll').multiscroll.destroy();
}

function mount() {
  const siteBackgroundVideo = document.querySelectorAll('.js-background-video');
  const $multiscroll = $('#multiscroll');

  $multiscroll.multiscroll({
		verticalCentered : true,
		scrollingSpeed: 800,
		easing: Power4.InOut,
		menu: false,
		sectionsColor: [],
		navigation: false,
		loopBottom: false,
		loopTop: false,
		css3: true,
		paddingTop: 0,
		paddingBottom: 0,
		normalScrollElements: null,
		scrollOverflow: false,
		scrollOverflowOptions: null,
		keyboardScrolling: true,
		touchSensitivity: 5,

		//responsive
		responsiveWidth: 768,
		responsiveHeight: 0,
    responsiveExpand: true,
    responsiveExpandKey: 'YWdlbmNlYWRyLmZyX3owYmNtVnpjRzl1YzJsMlpVVjRjR0Z1WkE9PVNoOA==',

    onLeave: (index, nextIndex) => {
      if (nextIndex === 1) {
        Array.from(siteBackgroundVideo).forEach(video => { video.play() });
      }
    },

    afterLoad: (anchorLink, index) => {
      if (index > 1) {
        Array.from(siteBackgroundVideo).forEach(video => { video.pause() });
      }
    },
  });
}

export default { mount, unmount }
