import Swiper, { Navigation, Pagination } from 'swiper';

function  init() {
  new Swiper('.rse-project-slider', {
    // Optional parameters
    loop: true,
    modules: [Navigation, Pagination],
    pagination: {
      el: '.swiper-pagination',
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });
}

export default { init }
