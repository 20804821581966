import videoPlayer from './videoPlayer';
import { gsap, Power0 } from 'gsap';

gsap.ticker.fps(24);

function init() {
  const openModalTriggers = document.querySelectorAll('.js-open-modal');

  Array.from(openModalTriggers).forEach((el) => {
    el.addEventListener('click', () => {
      const target = document.getElementById(el.dataset.modal);
      const modal = target.cloneNode(true);
      modal.id = `${modal.id}-clone`;
      document.body.appendChild(modal);

      // first close active modal
      unmount();

      // Stop video Background
      const siteBackgroundVideo = document.querySelectorAll('.js-background-video');
      Array.from(siteBackgroundVideo).forEach(video => { video.pause() });

      // Finally display it
      gsap.to(modal, .4, {
        force3D:true,
        autoAlpha: 1,
        ease:Power0.easeout,
        onComplete: () => {
          modal.classList.add('is-active');

          // Start video inside modal
          Array.from(modal.querySelectorAll('.js-video-player')).map(el => {
            const video = videoPlayer.init(el);
            video.on('ready', () => {
              video.muted = false;
              video.increaseVolume(1);
              video.play();
            });
          });

          // Bind Close event
          const closeModalTriggers = modal.querySelectorAll('.js-close-modal');
          closeModalTriggers.forEach((el) => {
            el.addEventListener('click', () => {
              close(modal);
            });
          });
        },
      }
      );
    });
  })
}

function unmount() {
  const modals = document.querySelectorAll('.video-modal.is-active');
  Array.from(modals).forEach((el) => {
    close(el);
  });
}

function close(modal) {
  // First stop the played video
  const videos = modal.querySelectorAll('.plyr');
  Array.from(videos).forEach(el => {
    const video = videoPlayer.get(el);
    if (typeof video === 'object') {
      video.pause();
    }
  });

  // Then directly relaunch the background video
  const siteBackgroundVideo = document.querySelectorAll('.js-background-video');
  Array.from(siteBackgroundVideo).forEach(video => { video.play() });

  // Finally hide the modal
  gsap.to(modal, .8, {
    force3D:true,
    autoAlpha: 0,
    ease:Power0.easeout,
    onComplete: () => {
      // Stop and destroy the players
      Array.from(videos).forEach(el => {
        const video = videoPlayer.get(el);
        if (typeof video === 'object') {
          video.stop();
          videoPlayer.unmount(el);
        }
      });
      modal.parentNode.removeChild(modal);
    },
  });
}

export default { init, unmount }
