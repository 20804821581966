import FakeProgress from 'fake-progress';
import EventEmitter from 'events';
import { gsap } from 'gsap';

gsap.ticker.fps(24);

function hideLayer() {
  const introLayer = document.querySelector('.intro-layer');
  gsap.to(introLayer.querySelectorAll('.intro-layer__inner'), .2, {
    autoAlpha: 0,
  });

  gsap.to(introLayer, 1.2, {
    autoAlpha: 0,
    onComplete: () => {
      introLayer.classList.add('intro-layer--hidden');
    },
  });
}

function init() {
  const referrer = document.referrer ? new URL(document.referrer) : null;

  if (!referrer || (referrer.hostname !== location.hostname)) {
    const timeConstant = Math.floor(Math.random() * (200 - 160 + 1) + 160);
    const introLayer = document.querySelector('.intro-layer');
    const placeholder = document.querySelector('.js-loading-percentage');
    introLayer.classList.add('intro-layer--initialized');

    const a = function (cb) {
      setTimeout(() => {
        cb();
      }, timeConstant / 6);
    };

    const c = function (cb) {
      setTimeout(() => {
        cb();
        hideLayer();
      }, timeConstant);
    };

    class B extends EventEmitter {
      constructor() {
        super();
      }

      start() {
        let count = 0;
        const _this = this;
        const totalCount = 25;
        _this.emit('start', count / totalCount);
        _this._intervalId = setInterval(() => {
          count++;
          if (count >= totalCount) {
            _this.emit('end', count / totalCount);
            clearInterval(self._intervalId);
          } else if (count <= totalCount / 1.5) {
            _this.emit('progress', Math.min(count / totalCount, .42));
          } else if (count <= totalCount / 3) {
            _this.emit('progress', Math.min(count / totalCount, .49));
          } else {
            _this.emit('progress', Math.min(count / totalCount, .56));
          }
        }, 100);
      }
    }

    const p = new FakeProgress({});

    const onEachDeciSecond = function () {
      placeholder.textContent = (p.progress * 100).toFixed(0);
    };

    onEachDeciSecond();

    const interval = setInterval(onEachDeciSecond, 100);

    const aProgress = p.createSubProgress({
      timeConstant: timeConstant / 8,
      end: 0.6,
      autoStart: true,
    });

    a(err => {
      if (err) {
        throw (err);
      }
      aProgress.stop();
      const bProgress = p.createSubProgress({
        end: 0.8,
      });


      const b = new B();
      b.on('progress', progress => {
        bProgress.setProgress(progress);
      });
      b.on('end', () => {
        bProgress.stop();
        const cProgress = p.createSubProgress({
          timeConstant: timeConstant / 6,
          autoStart: true,
        });
        c(() => {
          cProgress.end();
          onEachDeciSecond();
          clearInterval(interval);
        });
      });
      b.start();
    });
  } else {
    hideLayer();
  }
}

export default { init }
